import PropTypes from "prop-types";
import React from "react";

const LinkButton = ({
  children,
  url,
  onClick,
  fullWidth = false,
  size = "sm",
  additionalClass = "",
  target = "_top",
}) => (
  <div
    className={`inline-flex ${
      fullWidth ? "w-full sm:w-auto" : ""
    } rounded-md shadow ${additionalClass}`}
  >
    <a
      href={url}
      onClick={onClick}
      target={target}
      className={`flex ${
        fullWidth ? "w-full sm:w-auto" : ""
      } sm:w-auto items-center justify-center px-5 py-${
        size === "sm" ? 1 : 3
      } border border-transparent text-${size} leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out`}
    >
      {children}
    </a>
  </div>
);
LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  additionalClass: PropTypes.string,
  size: PropTypes.string,
  target: PropTypes.string,
};

export default LinkButton;
