import PropTypes from "prop-types";
import React from "react";

const LogoCloud = ({ logos }) => (
  <>
    <p className="text-center text-base leading-6 uppercase text-gray-500 tracking-wider mt-8">
      Trusted by
    </p>
    <div
      className={`grid grid-cols-2 gap-8 md:grid-cols-${Math.min(
        logos.length,
        6
      )} lg:grid-cols-${Math.min(logos.length, 5)} mt-8`}
    >
      {logos.map((logo) => (
        <a href={logo.url} key={logo.name}>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-12 object-contain"
              src={require(`../images/logos/${logo.name}.${
                logo.type || "svg"
              }`)}
              alt={logo.name}
            />
          </div>
        </a>
      ))}
    </div>
  </>
);

LogoCloud.propTypes = {
  logos: PropTypes.array.isRequired,
};

export default LogoCloud;
