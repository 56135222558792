import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";

import Header from "./header";
import TextLogo from "../images/minthesize_text_logo.svg";

function Layout({ children }) {
  return (
    <>
      <div className="relative bg-gray-100 min-h-screen overflow-hidden">
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Gudea:400,400i,500,700|Jura:400,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Header />
        <div className="mt-10 mb-5 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div className="text-center">
            <figure className="w-2/3 md:w-1/2 mx-auto mb-4">
              <img alt="Minthesize text logo" src={TextLogo} />
            </figure>
            <h1 className="text-4xl tracking-tight leading-10 font-extrabold sm:text-5xl sm:leading-none md:text-6xl font-display mb-8">
              <span className="text-magenta">Vienna Codewright</span>
            </h1>

            {children()}
          </div>
        </div>
        <footer className="relative bottom-0 w-full mx-auto py-12 px-4 overflow-hidden bg-gray-800 sm:px-6 lg:px-8 mt-20">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            <div className="px-5 py-2">
              <Link
                to="/imprint"
                className="text-base leading-6 text-gray-500 hover:text-gray-200"
              >
                Imprint
              </Link>
            </div>
          </nav>
          <div className="mt-8 flex justify-center">
            <a
              href="https://twitter.com/julian_rubisch"
              className="text-gray-400 hover:text-gray-200"
            >
              <span className="sr-only">Twitter</span>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://github.com/julianrubisch"
              className="ml-6 text-gray-400 hover:text-gray-200"
            >
              <span className="sr-only">GitHub</span>
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
          <div className="px-5 py-2">
            <div className="mt-8">
              <p className="text-center text-base leading-6 text-gray-500">
                &copy; Minthesize e.U. // DI Julian Rubisch // ATU68948936 // FN
                423338 g // Handelsgericht Wien{" "}
              </p>
            </div>
          </div>
        </footer>{" "}
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Layout;
