import { Link } from "gatsby";
import React, { useState } from "react";

import SquareLogo from "../images/minthesize_logo_square.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  // const { site } = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  const pages = [
    {
      route: `/`,
      title: `Services`,
    },
    {
      route: `/team`,
      title: `Team`,
    },
    {
      route: `/contact`,
      title: `Contact`,
    },
    {
      route: `https://blog.minthesize.com`,
      title: `Blog`,
    },
  ];

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-12 md:justify-center">
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/">
                <img
                  className="h-8 w-auto sm:h-10"
                  src={SquareLogo}
                  alt="Minthesize square logo"
                />
              </Link>
              <div className="-mr-2 flex items-center md:hidden">
                <button
                  onClick={() => toggleExpansion(!isExpanded)}
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            {pages.map((link, index) =>
              link.route.startsWith("https://") ? (
                <a
                  href={link.route}
                  className="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out ml-10"
                >
                  {link.title}
                </a>
              ) : (
                <Link
                  className={`font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out ${
                    index > 0 ? "ml-10" : ""
                  }`}
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </Link>
              )
            )}
          </div>
        </nav>
      </div>
      <div
        className={`absolute top-0 inset-x-0 p-2 md:hidden ${
          isExpanded ? `block` : `hidden`
        }`}
      >
        <div className="rounded-lg shadow-md transition transform origin-top-right">
          <div className="rounded-lg bg-white shadow-xs overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <img className="h-8 w-auto" src={SquareLogo} alt="" />
              </div>
              <div className="-mr-2">
                <button
                  onClick={() => toggleExpansion(!isExpanded)}
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {pages.map((link, index) => 
              link.route.startsWith("https://") ?
              (
                <a className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out ${index > 0 ? "mt-1" : ""}`} href={link.route} key={link.title}>{link.title}</a>
              ) : (
                <Link
                  className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out ${
                    index > 0 ? "mt-1" : ""
                  }`}
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
