import PropTypes from "prop-types";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import LinkButton from "../components/LinkButton";

const Card = ({
  children,
  header,
  previousUrl,
  nextUrl,
  previousLabel,
  nextLabel,
}) => {
  return (
    <div className="rounded-lg shadow-lg overflow-hidden bg-white xl:w-2/3 md:w-3/4 sm:w-full mx-auto">
      {header && (
        <div className="bg-white px-6 py-5 border-b border-gray-200 sm:px-10">
          <h1 className="text-xl text-left leading-6 font-medium text-gray-900">
            {header}
          </h1>
        </div>
      )}
      <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
        {children}
      </div>
      {(previousUrl || nextUrl) && (
        <div className="bg-white px-4 py-3 flex items-center justify-end border-t border-gray-200 sm:px-6">
          {previousUrl && (
            <LinkButton url={previousUrl}>
              <FontAwesomeIcon icon={faAngleLeft} className="mr-3" />
              {previousLabel || "Back"}
            </LinkButton>
          )}
          {nextUrl && (
            <LinkButton url={nextUrl} additionalClass="ml-3">
              {nextLabel || "Next"}{" "}
              <FontAwesomeIcon icon={faAngleRight} className="ml-3" />
            </LinkButton>
          )}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  visible: PropTypes.bool.isRequired,
  previousUrl: PropTypes.string,
  nextUrl: PropTypes.string,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
};

export default Card;
